import styled from 'styled-components';
import Theme from '../../theme';
import {VideoPlayer} from '../VideoPlayer';

interface ZIndexSwitch {
  maintainIndex: boolean;
}

export const RevealHeroContainer = styled.div<ZIndexSwitch>`
  position: relative;
  width: 100%;
  height: 580px;
  overflow: hidden;
  display: flex;
  place-items: center;

  h3 {
    color: white;
    z-index: 200;
    text-transform: uppercase;
  }

  div {
    color: white;
    display: flex;
    ${({maintainIndex}) => (maintainIndex ? 'z-index: 200' : '0')};
  }
`;

export const RevealHeroContent = styled.div`
  display: flex;
  place-items: center;
  flex-direction: column;
  width: auto;
  max-width: 90%;
  margin: auto;
`;

export const Description = styled.p<ZIndexSwitch>`
  margin-top: 30px;
  color: white;
  max-width: 501px;
  max-height: 78px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  ${({maintainIndex}) => (maintainIndex ? 'z-index: 200' : '0')};
`;

export const ProdLogo = styled.img<ZIndexSwitch>`
  max-width: 502px;
  margin: 0 25px;
  left: 469px;
  top: 264px;
  ${({maintainIndex}) => (maintainIndex ? 'z-index: 200' : '0')};
`;

export const ButtonsWrapper = styled.div`
  margin-top: 60px;
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
  }
`;

export const Walkthrough = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    text-transform: uppercase;
    margin-left: 12px;
    width: fit-content;
    margin-top: 0;
    height: fit-content;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-family: ${Theme.fonts.heading};

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
`;

export const TryButton = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 40px;

  color: #f5f5f5;

  border: 2px solid #499bae;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const HeroVideo = styled.video`
  position: absolute;
  min-width: 100vw;
  max-height: 580px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

export const WaltkthroughVideo = styled(VideoPlayer)`
  max-width: 1200px;
  height: 100%;

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    height: 52vw;
  }

  #exit {
    position: absolute;
    height: 35px;
    width: 35px;
    top: 15px;
    right: 15px;
    z-index: 801;
    padding: 0px;
    background-color: ${Theme.colours.red};
    border-radius: 50%;
    border: unset;
    display: flex;
    place-items: center;
    background-image: url(/images/exit.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;
    img {
      margin: auto;
    }
  }
`;

export const WalkthroughVideoContainer = styled.div`
  position: absolute;
  padding: 2px;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 800;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colours.darkGrey};
`;

import {PlansListPlansInner} from '@focusrite-novation/ampify-api';
import {Modal} from '@focusrite-novation/ampify-web-ui';
import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CollectiveSupportedDevices, pathFor} from '../../constants/paths';
import {getFastPlugins} from '../../lib/plans';
import Theme from '../../theme';
import {sendAnalyticsEvent} from '../../utils/countly';
import {getAllPluginLinks} from '../../utils/download-links';
import {ButtonVariant} from '../Button/ButtonVariant';
import {DownloadButton} from '../DownloadButton';
import {getUserFromLocalStorage} from '../../utils/user-info';

const List = styled.ul`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  li {
    list-style: none;
    margin: ${Theme.space[4]}px;
  }
`;

const Container = styled.div`
  width: 800px;
  max-width: calc(100% - 20px);
`;

export interface DownloadModalProps {
  plans: PlansListPlansInner[];
  baseUrl: string;
  handleClose: () => void;
  softwareId: string;
  page: string;
  os: CollectiveSupportedDevices;
}

const getPluginName = (softwareId: string): string => {
  const splitId = softwareId.split('.');
  return splitId[splitId.length - 1];
};

export const DownloadModal: FC<DownloadModalProps> = ({
  plans,
  baseUrl,
  handleClose,
  softwareId,
  page,
  os,
}) => {
  const [buttonVariant, setButtonVariant] = useState(ButtonVariant.PRIMARY);
  const [showForm, setShowForm] = useState<boolean>(false);

  const pluginsDownloadLinks = getAllPluginLinks(plans, baseUrl);

  const handleSingleDownload = (
    os: CollectiveSupportedDevices,
    link: string,
    productSoftwareId: string
  ) => {
    sendAnalyticsEvent(`download-product`, {
      product: productSoftwareId,
      page,
      os,
    });

    window.location.assign(link);
  };

  const handleBundleDownload = (os: CollectiveSupportedDevices) => {
    setButtonVariant(ButtonVariant.LOADING);

    sendAnalyticsEvent('download-product', {
      product: `${getPluginName(softwareId)}-all-plugins`,
      page,
      os,
    });

    pluginsDownloadLinks[os].forEach((url, i) =>
      // if we dont allow some time between the location assignment then only the last link will be downloaded
      setTimeout(() => {
        window.location.assign(url);

        if (i === pluginsDownloadLinks[os].length - 1) {
          setButtonVariant(ButtonVariant.PRIMARY);
        }
      }, i * 1500)
    );
  };

  const formatSoftwareId = (softwareId: string) => {
    const splitId = softwareId.split('.');
    const pluginName = splitId[splitId.length - 1];
    const [fast, name] = pluginName.split('-');
    return `Download ${fast.toUpperCase()} ${name[0].toUpperCase()}${name.slice(
      1
    )}`;
  };

  const softwareIdsToDisplay: string[] = plans
    .filter(getFastPlugins())
    .map(({softwareIds}) => softwareIds![0]);

  return (
    <Modal
      maxWidth="900px"
      panelBackground={Theme.colours.greys[7]}
      handleClose={handleClose}
      panelDataTestid="download-panel"
    >
      {showForm ? (
        <Container>
          <div id="hubspot"></div>
        </Container>
      ) : (
        <div>
          <h3
            style={{color: Theme.colours.white, textAlign: 'center'}}
            data-testid="download-title"
          >
            Download the FAST Plugins
          </h3>
          <List data-testid="link-list">
            <li>
              <DownloadButton
                os={os}
                buttonVariant={buttonVariant}
                buttonText="Download all plugins"
                softwareId={softwareId}
                onDownloadBundle={handleBundleDownload}
              />
            </li>
            {softwareIdsToDisplay.map((innerSoftwareId: string) => {
              return (
                <li key={innerSoftwareId}>
                  <DownloadButton
                    os={os}
                    onDownload={handleSingleDownload}
                    buttonText={formatSoftwareId(innerSoftwareId)}
                    links={pathFor(baseUrl, innerSoftwareId)}
                    softwareId={innerSoftwareId}
                  ></DownloadButton>
                </li>
              );
            })}
          </List>
        </div>
      )}
    </Modal>
  );
};

import {ProductsList} from '@focusrite-novation/ampify-api';
import {MergedPlan} from '../lib/my-account/fetch-plans';
import {
  getAccessibleIds,
  MergedPurchase,
} from '../lib/my-account/fetch-products';

export const shouldContinueToPurchase = async (
  softwareId: string,
  softwareProducts: ProductsList,
  purchases: MergedPurchase[],
  plans: MergedPlan[]
): Promise<boolean> => {
  const requestedIds = softwareId.includes('com.focusrite')
    ? [softwareId]
    : softwareProducts?.products?.find(({name}) =>
        name?.toLowerCase().includes('fast bundle')
      )?.softwareIds;

  const accessibleIds = getAccessibleIds([...purchases, ...plans]);

  if (!requestedIds) {
    return true;
  }

  return requestedIds.filter((id) => !accessibleIds.has(id))?.length > 0;
};

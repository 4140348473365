import React, {FC, useState} from 'react';
import {useLocation} from '@reach/router';
import PlayIcon from '../../images/play-icon.svg';
import {
  RevealHeroContainer,
  ProdLogo,
  ButtonsWrapper,
  Walkthrough,
  Description,
  HeroVideo,
  WaltkthroughVideo,
  WalkthroughVideoContainer,
  RevealHeroContent,
} from './RevealHero.styles';
import {Spacer} from '../Spacing/Spacer';
import {AssetFile} from '../../types/ProductV2';
import {isChosenPlugin} from '../../lib/payment';

interface RevealHeroProps {
  prodLogo: AssetFile;
  heroDescription: string;
  heroVideo: string;
  heroTitle: string;
}

export const RevealHero: FC<RevealHeroProps> = ({
  prodLogo,
  heroDescription,
  heroVideo,
  heroTitle,
}) => {
  const location = useLocation();
  const [showWalkthrough, setShowWalkthrough] = useState(false);

  return (
    <RevealHeroContainer maintainIndex={!showWalkthrough}>
      <RevealHeroContent>
        <h3>{heroTitle}</h3>

        <Spacer height="57px" />

        <ProdLogo maintainIndex={!showWalkthrough} src={prodLogo?.file?.url!} />
        <Description maintainIndex={!showWalkthrough}>
          {heroDescription}
        </Description>
        <ButtonsWrapper>
          {isChosenPlugin('fast-reveal', location) ? (
            <Walkthrough
              className="walkthrough"
              onClick={() => setShowWalkthrough(true)}
            >
              <img src={PlayIcon} /> <p>watch walkthrough</p>
            </Walkthrough>
          ) : null}
        </ButtonsWrapper>
      </RevealHeroContent>

      <HeroVideo autoPlay loop muted playsInline>
        <source src={heroVideo} type="video/mp4" />
      </HeroVideo>

      {showWalkthrough ? (
        <WalkthroughVideoContainer>
          <WaltkthroughVideo
            handleClose={() => setShowWalkthrough(false)}
            showCloseButton
            playerID="fast-reveal"
            videoID="UghsSwbXua8"
            testID="walkthrough-player"
          />
        </WalkthroughVideoContainer>
      ) : null}
    </RevealHeroContainer>
  );
};

import {Link} from 'gatsby';
import styled from 'styled-components';
import Theme from '../../theme';

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  height: 100%;
  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    width: unset;
  }
`;

interface CardContainerProps {
  maxWidthPercent?: number;
}

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  max-width: ${({maxWidthPercent}) => `${maxWidthPercent || 32}%`};
  min-width: 256px;
  height: 525px;
  background-color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    max-width: 400px;
  }
`;

export const TextContainer = styled.div`
  padding: 1rem;
  height: 100%;
  position: relative;

  h4 {
    text-transform: uppercase;
    font-family: ${Theme.fonts.heading};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-align: left;
    margin-bottom: 16px !important;
  }

  p {
    font-family: ${Theme.fonts.body};
    font-size: 20px;
    line-height: 26px;
  }

  div > p {
    font-family: ${Theme.fonts.heading};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #499bae;
    bottom: 10px;
    position: absolute;
    padding-bottom: inherit;
    text-decoration: none;
  }
`;

export const ImageContainer = styled.div`
  border-radius: 5px 5px 0 0;
`;

export const FastCollectionCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  row-gap: 20px;

  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    align-items: center;
    flex-direction: column;
  }
  @media screen and (min-width: ${Theme
      .breakpoints[2]}px) and (max-width: 1300px) {
    justify-content: space-around;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  p {
    font-size: 20px;
    text-align: center;

    font-family: ${Theme.fonts.body};
  }
  img {
    width: 637px;
    height: 65px;
  }
`;

import styled from 'styled-components';
import {Theme} from '../../theme/Theme';

export const StyledTestimonial = styled.div`
  color: ${Theme.colours.white};
  flex: 1 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${Theme.space[9]}px;
  * {
    flex-grow: 0;
  }
  p {
    flex-grow: 2;
  }
`;

interface TestimonialWrapperProps {
  extendMarginLeft?: boolean;
}

export const TestimonialWrapper = styled.div<TestimonialWrapperProps>`
  height: 380px;
  max-width: 930px;
  width: 60vw;
  margin-left: 25px;

  :first-child {
    margin-left: 0;
  }
  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    margin-left: 0;
    height: fit-content;
    width: unset;
  }

  display: inline-block;

  font-family: ${Theme.fonts.heading};
  font-style: italic;
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.06em;

  color: #212829;
`;

export const TestimonialContainer = styled.div`
  display: flex;
  gap: 40px;
  width: fit-content;
  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
`;

export const TestimonialQuote = styled.p`
  /* width: 1150px; */
  height: 132px;
  white-space: normal;
  margin-left: 0px;

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    font-size: smaller;
    width: 70%;
    height: auto;
    margin-block-end: 0;
    margin-block-start: 0;

    margin: auto;
  }
  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    text-align: center;
    font-size: x-large;

    width: 100%;
    height: auto;
  }
`;

export const TestimonialAuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  img {
    width: 142px;
    height: 142px;
    object-fit: none;
  }
  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    justify-content: center;
  }
  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
`;

export const AuthorDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 25px;
  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    margin-left: 0px;
  }

  p {
    height: initial;
    margin-right: 5px;

    @media screen and (max-width: ${Theme.breakpoints[2]}px) {
      text-align: center;
      width: 100%;
      height: auto;
    }
  }

  .author {
    font-family: ${Theme.fonts.heading};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #212829;
  }

  .role {
    font-family: ${Theme.fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #435154;
  }
`;

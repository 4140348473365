import React, {FC} from 'react';
import {
  TestimonialContainer,
  TestimonialQuote,
  TestimonialWrapper,
  TestimonialAuthorWrapper,
  AuthorDetails,
} from './Testimonial.styles';
import {FluidObject} from 'gatsby-image';
import {GutterMaxWidth, Gutters} from '../Spacing/Gutters';
import {Testimonial} from '../../types/ProductV2';
import {Spacer} from '../Spacing/Spacer';

export interface TestimonialProps {
  /** The testimonial */
  quote: string;
  /** The name of the person giving the testimonial */
  name: string;
  /** About the person giving the testimonial */
  about: string;
  /** image */
  fluid: FluidObject;
  testID: string;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
}

export const Testimonials: FC<TestimonialsProps> = ({testimonials}) => {
  return (
    <div>
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <div style={{overflowX: 'scroll'}}>
          <TestimonialContainer>
            {testimonials?.map((testimonial, i) => {
              return (
                <TestimonialWrapper
                  key={testimonial?.author!}
                  extendMarginLeft={i === 0}
                >
                  <Spacer height="40px" />
                  <TestimonialQuote>"{testimonial?.quote}"</TestimonialQuote>
                  <TestimonialAuthorWrapper>
                    <img
                      src={testimonial?.authorImage?.file?.url!}
                      alt={testimonial?.authorImage?.title!}
                    />
                    <AuthorDetails>
                      <p className="author">{testimonial?.author}</p>
                      <p className="role">{testimonial?.authorRole}</p>
                    </AuthorDetails>
                  </TestimonialAuthorWrapper>
                </TestimonialWrapper>
              );
            })}
          </TestimonialContainer>
        </div>
      </Gutters>
    </div>
  );
};

import React, {
  CSSProperties,
  FunctionComponent,
  MouseEvent,
  useState,
} from 'react';
import {Button} from '../Button';
import {Dictionary} from '../../types/Dictionary';
import {
  Dropdown,
  DropdownToggle,
  StyledDownloadButton,
  Text,
} from './DownloadButton.styles';
import {Theme} from '../../theme/Theme';
import {detect, DeviceOS} from '../../utils/detect';
import {ArrowIcon, MacOSIcon, WindowsIcon} from '../Icon';
import {ButtonVariant} from '../Button/ButtonVariant';
import {Orientation} from '../Icon/ArrowIcon';
import {CollectiveSupportedDevices} from '../../constants/paths';

const {colours} = Theme;

interface DownloadButtonProps {
  /** the detected or default OS */
  os: DeviceOS;
  /** links to the downloads */
  links?: Dictionary<string>;
  /** pass in custom styles */
  style?: CSSProperties;
  /** used in for testing */
  testID?: string;
  tabIndex?: number;
  buttonText: string;
  softwareId?: string;
  // callback when the download button has been clicked
  onDownload?: (
    os: CollectiveSupportedDevices,
    link: string,
    productSoftwareId: string
  ) => void;
  /** handles the download and zip for all plugins in the bundle */
  onDownloadBundle?: (os: CollectiveSupportedDevices) => void;
  buttonVariant?: ButtonVariant;
  alternativeBackgroundColour?: string;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = ({
  os,
  links,
  style,
  testID,
  tabIndex,
  buttonText,
  softwareId,
  onDownload,
  onDownloadBundle,
  buttonVariant = ButtonVariant.YELLOW,
  alternativeBackgroundColour,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleClickDropdownToggle = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowOptions(!showOptions);
  };

  let defaultOS: CollectiveSupportedDevices;

  switch (detect.os()) {
    case DeviceOS.MAC_OS:
    case DeviceOS.WINDOWS:
      defaultOS = os as CollectiveSupportedDevices;
      break;
    default:
      return null;
  }

  const alternativeOS =
    defaultOS === DeviceOS.MAC_OS ? DeviceOS.WINDOWS : DeviceOS.MAC_OS;

  const handleDownload = (os: CollectiveSupportedDevices) => {
    if (onDownloadBundle && typeof onDownloadBundle === 'function') {
      onDownloadBundle(os);
    }

    if (onDownload) {
      const link = links?.[os] || '';
      onDownload(os, link, softwareId || '');
    }
  };

  const iconColour =
    alternativeBackgroundColour === Theme.colours.black
      ? Theme.colours.white
      : Theme.colours.black;

  return (
    <StyledDownloadButton style={style} data-testid={testID}>
      <Button
        id="main-download-button"
        background={alternativeBackgroundColour}
        href={links?.[defaultOS]}
        tabIndex={tabIndex}
        variant={buttonVariant}
        style={{zIndex: 1}}
        data-testid={`${testID}-main-button`}
        onClick={() => handleDownload(defaultOS)}
      >
        {defaultOS === DeviceOS.MAC_OS ? (
          <MacOSIcon colour={iconColour} />
        ) : (
          <WindowsIcon colour={iconColour} />
        )}
        <Text>{buttonText}</Text>
      </Button>
      <DropdownToggle
        onClick={handleClickDropdownToggle}
        tabIndex={tabIndex}
        style={{zIndex: 2}}
        data-testid={`${testID}-dropdown`}
      >
        <ArrowIcon
          orientation={showOptions ? Orientation.UP : Orientation.DOWN}
        />
      </DropdownToggle>
      <Dropdown className="alternate-wrapper" showOptions={showOptions}>
        <Button
          className="alternate-download-button"
          onClick={() => handleDownload(alternativeOS)}
          variant={ButtonVariant.MONOCHROME}
          as="div"
          style={{border: 'none'}}
          data-testid={`${testID}-alternate-button`}
          tabIndex={tabIndex}
        >
          {alternativeOS === DeviceOS.MAC_OS ? (
            <MacOSIcon colour={colours.white} />
          ) : (
            <WindowsIcon colour={colours.white} />
          )}
          <Text>{buttonText}</Text>
        </Button>
      </Dropdown>
    </StyledDownloadButton>
  );
};

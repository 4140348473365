import {PlansListPlansInner} from '@focusrite-novation/ampify-api';
import {CollectiveSupportedDevices, pathFor} from '../constants/paths';
import {DeviceOS} from './detect';
import {getBundle, getFastPlugins} from '../lib/plans';

export interface BlobInfo {
  filename: string;
  blob: Blob;
}

interface DownloadLinks {
  Mac: string[];
  Windows: string[];
}

enum LinkOptions {
  Bundle,
  AllPlugins,
}

const isPlansListPlansInner = (val: any): val is PlansListPlansInner => {
  return Object.keys(val).includes('softwareIds');
};

const getSoftwareId = (val: string | PlansListPlansInner): string => {
  if (typeof val === 'string') {
    return val;
  }

  return val.softwareIds![0];
};

const buildLinks = <T>(arr: T[], baseUrl: string) => {
  return arr.reduce(
    (links: DownloadLinks, element: T) => {
      const softwareId = getSoftwareId(element);
      const paths = pathFor(baseUrl || '', softwareId);

      Object.keys(paths).forEach((os) =>
        links[os as CollectiveSupportedDevices].push(
          paths[os as CollectiveSupportedDevices]
        )
      );
      return links;
    },
    {
      [DeviceOS.WINDOWS]: [],
      [DeviceOS.MAC_OS]: [],
    }
  );
};

export const getAllPluginLinks = (
  plans: PlansListPlansInner[],
  baseUrl: string
): DownloadLinks => {
  const plugins = plans.filter(getFastPlugins());
  return buildLinks(plugins, baseUrl);
};

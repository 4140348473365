import {FluidObject} from 'gatsby-image';
import React from 'react';
import Img from 'gatsby-image';

import {
  CardContainer,
  ImageContainer,
  TextContainer,
  StyledLink,
} from './FastCollectionCard.styles';
import {FastCollection} from '../../types/ProductV2';
import {Spacer} from '../Spacing/Spacer';
import Theme from '../../theme';

type FastCollectionCard = {
  product: FastCollection;
  maxWidthPercentage?: number;
};

export const FastCollectionCard: React.FC<FastCollectionCard> = ({
  product,
  maxWidthPercentage,
}) => {
  return (
    <>
      <CardContainer maxWidthPercent={maxWidthPercentage}>
        <StyledLink to={product.pluginUrl!}>
          <ImageContainer>
            <Img
              fluid={product?.pluginImage?.fluid! as FluidObject}
              alt={product?.pluginImage?.title!}
              style={{borderRadius: 'inherit'}}
            />
          </ImageContainer>
          <TextContainer>
            <h4>{product.pluginName}</h4>
            <p>{product.pluginDescription}</p>
            <div>
              <p>Learn More {'>'}</p>
            </div>
          </TextContainer>
        </StyledLink>
      </CardContainer>
    </>
  );
};

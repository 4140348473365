import styled from 'styled-components';
import Theme from '../../theme';

interface BackgroundProps {
  background?: string;
}

export const Background = styled.div<BackgroundProps>`
  background: ${({background}) => background ?? '#212829'};
`;

interface WrapperProps {
  isOdd: boolean;
  centred?: boolean;
}

export const Section = styled.div<WrapperProps>`
  width: 100%;
  min-height: 900px;
  background: ${({isOdd}) => (isOdd ? '#212829' : '#F5F5F5')};
  display: flex;
  flex-direction: ${({isOdd}) => (isOdd ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: space-between;
  color: ${({isOdd}) => (isOdd ? '#F5F5F5' : '#212829')};

  gap: 50px;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin-top: 25px;
  }
  /* @media screen and (max-width: ${Theme.breakpoints[1]}px) {
  } */

  h3 {
    max-width: 485px;
    width: 100%;
    font-family: ${Theme.fonts.heading};
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    margin-bottom: 24px;

    @media screen and (max-width: ${Theme.breakpoints[0]}px) {
      font-size: 29px;
    }
  }

  .color-bar {
    max-width: 360px;
    width: 100%;
    height: 6px;
    left: 811px;
    top: 2945px;
    margin-bottom: 40px;

    background: linear-gradient(
      90deg,
      #499bae 0%,
      #b75eae 21.88%,
      #c34c54 47.92%,
      #e28b4e 72.4%,
      #e7ca65 100%
    );
  }
`;

export const ImgWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: end;
  flex: 1 1 0;
  width: 100%;

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    justify-content: ${({isOdd}) => (isOdd ? 'start' : 'end')};
    height: 100%;
  }

  @media screen and (max-width: 1050px) {
    justify-content: center;
    flex: unset;
    max-width: 485px;
    img {
      height: 50vw;
    }
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    justify-content: center;
    flex: unset;
    img {
      height: 70vw;
    }
  }
`;

export const TextWrapper = styled.div<WrapperProps>`
  /* width: 100%; */
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: ${({isOdd, centred}) => (isOdd ? 'end' : 'start')};

  p {
    width: 100%;
    font-family: ${Theme.fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    margin-top: 26px;
    letter-spacing: 0.04em;
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    margin: 0 auto;
    align-items: center;
  }
`;

import {PlansListPlansInner} from '@focusrite-novation/ampify-api';
import {Currency, detect} from '@focusrite-novation/ampify-web-ui';
import {Link} from 'gatsby';
import React, {FC, useEffect, useState} from 'react';
import {CollectiveSupportedDevices} from '../../constants/paths';
import {DeviceOS} from '../../utils/detect';
import {formatPrice} from '../../utils/get-plan-pricing';
import {DownloadModal} from '../DownloadModal/DownloadModal';
import {TryButton} from '../RevealHero/RevealHero.styles';
import {
  BuyBarContainer,
  TryContainer,
  BuyNowContainer,
  BuyBarWrapper,
  DownloadWrapper,
} from './BuyButton.styles';

interface BuyBarProps {
  plans: PlansListPlansInner[];
  usdPrice?: number;
  baseUrl: string;
  softwareId: string;
  continueFlow: boolean;
}

export const BuyBar: FC<BuyBarProps> = ({
  plans,
  usdPrice,
  baseUrl,
  softwareId,
  continueFlow,
}) => {
  const [showTry, setShowTry] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [bundleDownloadOs, setBundleDownloadOs] = useState<
    undefined | CollectiveSupportedDevices
  >();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);

  const handleScroll = () => {
    setIsFixed(window.scrollY > 630);
  };

  useEffect(() => {
    const currentOS = detect.os();
    const supportedDevices = [DeviceOS.MAC_OS, DeviceOS.WINDOWS];
    if (supportedDevices.includes(currentOS)) {
      setShowTry(true);
      setBundleDownloadOs(currentOS as CollectiveSupportedDevices);
    } else {
      setShowTry(false);
    }
  }, [detect.os()]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const productPrice = usdPrice ?? 89.85;
  const formattedPrice = formatPrice(Currency.USD, productPrice);

  return (
    <>
      <BuyBarWrapper fixed={isFixed}>
        <BuyBarContainer isDesktop={showTry}>
          {showTry && (
            <TryContainer>
              <TryButton onClick={() => setShowDownloadModal((val) => !val)}>
                try for free
              </TryButton>
            </TryContainer>
          )}
          <BuyNowContainer>
            <div>
              <p>{formattedPrice}</p>
              <p>or pay in 15 instalments</p>
            </div>
            <Link
              data-testid="buy-now"
              to={
                continueFlow ? `/plan-selection/${softwareId}` : '/my-account'
              }
            >
              Buy now
            </Link>
          </BuyNowContainer>
        </BuyBarContainer>
      </BuyBarWrapper>
      {showDownloadModal && (
        <DownloadWrapper>
          <DownloadModal
            os={bundleDownloadOs as CollectiveSupportedDevices}
            page="product"
            plans={plans}
            baseUrl={baseUrl}
            handleClose={() => setShowDownloadModal(false)}
            softwareId={softwareId}
          />
        </DownloadWrapper>
      )}
    </>
  );
};

import {DeviceOS} from '../utils/detect';

export type CollectiveSupportedDevices = DeviceOS.WINDOWS | DeviceOS.MAC_OS;

export function pathFor(
  releasesHost: string,
  softwareId: string
): {[k in CollectiveSupportedDevices]: string} {
  return {
    [DeviceOS.WINDOWS]: `${releasesHost}/${softwareId}/latest/${softwareId}.exe`,
    [DeviceOS.MAC_OS]: `${releasesHost}/${softwareId}/latest/${softwareId}.dmg`,
  };
}

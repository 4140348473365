import React, {FC} from 'react';
import Theme from '../../theme';
import {ProductFeature} from '../../types/ProductV2';
import {GutterMaxWidth, Gutters} from '../Spacing/Gutters';
import {Spacer} from '../Spacing/Spacer';
import {
  Background,
  ImgWrapper,
  Section,
  TextWrapper,
} from './ProductFeature.styles';

interface ProductFeatureSectionProps {
  isOdd: boolean;
  feature: ProductFeature;
  i: number;
}

export const ProductFeatureSection: FC<ProductFeatureSectionProps> = ({
  isOdd,
  feature,
  i,
}) => {
  const centred = false;
  return (
    <Background
      background={isOdd ? Theme.colours.greys[8] : Theme.colours.greys[0]}
    >
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <Spacer height="40px" />
        <Section isOdd={isOdd}>
          <ImgWrapper isOdd={isOdd} centred={centred}>
            <img src={feature?.image?.file?.url!} />
          </ImgWrapper>
          <TextWrapper isOdd={isOdd} centred={centred}>
            <div>
              <Spacer height="40px" />
              <h3>{feature?.title!}</h3>
              <div className="color-bar"></div>
              {feature?.description?.description?.split('\n').map((section) => (
                <p key={section}>{section}</p>
              ))}
            </div>
          </TextWrapper>
        </Section>
      </Gutters>
      <Spacer height="50px" />
    </Background>
  );
};

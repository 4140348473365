import {graphql, useStaticQuery} from 'gatsby';
import React, {FC} from 'react';
import {FastCollectionCardContainer, FastCollectionCard} from '.';
import {FastCollection as OtherPlugins} from '../../types/ProductV2';
import {Group} from '../Group';
import {Spacer} from '../Spacing/Spacer';
import {TextWrapper} from './FastCollectionCard.styles';

interface FastCollectionProps {
  collectionCards: OtherPlugins[];
}

export const FastCollection: FC<FastCollectionProps> = ({collectionCards}) => {
  const data = useStaticQuery(graphql`
    query FastCollectionQuery {
      img: contentfulAsset(contentful_id: {eq: "51c3ENKJWR1fvjmWkjDTBf"}) {
        title
        file {
          url
        }
      }
    }
  `);

  return (
    <Group data-testid="highlight">
      <TextWrapper>
        <img src={data.img?.file?.url!} />
        <Spacer height="36px" />
        <p>
          Check out our other AI plugins that are simple for beginners and
          flexible for the most experienced creators.
        </p>
        <Spacer height="49px" />
      </TextWrapper>
      <FastCollectionCardContainer>
        {collectionCards.map((prod, index) => (
          <FastCollectionCard product={prod} key={prod.pluginName!} />
        ))}
      </FastCollectionCardContainer>
      <Spacer />
    </Group>
  );
};

import styled from 'styled-components';
import Theme from '../../theme';

const {colours} = Theme;

export const StyledDownloadButton = styled.div`
  position: relative;
  display: inline-flex;
`;

export const DropdownToggle = styled.button`
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colours.greys[7]};
  width: 42px;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    background-color: ${Theme.colours.black};
  }
`;

export const Text = styled.span`
  padding-left: 16px;
  text-align: left;
  min-width: max-content;
`;

const easeMe = ({showOptions}: DropdownProps) =>
  showOptions ? '0.3s ease-out' : '0.1s ease-in';

interface DropdownProps {
  showOptions: boolean;
}

export const Dropdown = styled.div<DropdownProps>`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  right: 0px;
  cursor: pointer;

  visibility: ${({showOptions}) => (showOptions ? 'visible' : 'hidden')};
  opacity: ${({showOptions}) => (showOptions ? '1' : '0')};
  top: ${({showOptions}) => (showOptions ? '100%' : '50%')};
  transition: visibility 0s linear
      ${({showOptions}) => (showOptions ? '0s' : '2s')},
    opacity ${(props) => easeMe(props)}, top ${(props) => easeMe(props)};

  a {
    width: 100%;
  }

  &:hover {
    background-color: ${colours.grey};
  }
`;

import styled from 'styled-components';
import Theme from '../../theme';

export const SystemRequirementsContainer = styled.div`
  color: #f5f5f5;

  a {
    margin-top: 32px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #77c1d5;
  }
  p {
    max-width: 535px;
  }

  .product-manual {
    margin-bottom: 32px;
  }
  h2 {
    font-family: ${Theme.fonts.heading};
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  h3 {
    font-family: ${Theme.fonts.heading};
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: larger;
  }

  li {
    list-style: none;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.04em;
  }

  .sys-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${Theme.breakpoints[3]}px) {
      flex-direction: column;
    }
  }
  > div {
    width: 100%;
  }
`;

export const ManualDownloadLink = styled.a`
  font-size: 20px !important;
  line-height: 26px !important;
`;

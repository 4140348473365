import React, {FC} from 'react';
import {ProductV2} from '../../types/ProductV2';
import {Spacer} from '../Spacing/Spacer';
import {
  ManualDownloadLink,
  SystemRequirementsContainer,
} from './SystemRequirements.styles';

interface SystemRequirementsProps {
  product: ProductV2;
}

export const SystemRequirements: FC<SystemRequirementsProps> = ({product}) => (
  <SystemRequirementsContainer>
    <h2>System Requirements</h2>
    <div className="sys-row">
      <div className="os">
        <h3>Operating Systems</h3>
        <ul>
          {product.operatingSystems?.operatingSystems?.split('\n').map((os) => (
            <li key={os}>{os}</li>
          ))}
        </ul>
      </div>
      <div className="sub-column">
        <h3>Supported Hosts</h3>
        <p>{product.supportedHosts}</p>
        <h3>Plugin Formats</h3>
        <p>{product.pluginInFormats}</p>
      </div>
    </div>
    <Spacer height="70px" />
    <div
      style={{
        width: '100%',
        height: '0px',
        border: '1.5px solid #5A6E73',
        background: '#5A6E73',
      }}
    ></div>

    <Spacer height="50px" />
    <ManualDownloadLink href={`/documents/${product.slug}.pdf`}>
      Download the Manual
    </ManualDownloadLink>
    <Spacer height="200px" />
  </SystemRequirementsContainer>
);

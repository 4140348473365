import React, {FunctionComponent, useEffect, useState} from 'react';
import {WindowLocation, useLocation} from '@reach/router';
import Image, {FixedObject} from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {FooterContent} from '../types/FooterContent';
import Theme from '../theme';
import {ProductFeatureSection} from '../components/ProductFeatureSection/ProductFeatureSection';
import {Background} from '../components/ProductFeatureSection/ProductFeature.styles';
import {KeyFeaturesSection} from '../pages/play/key-features';
import {KeyFeaturesReveal} from '../components/KeyFeatures';
import {Testimonials} from '../components/Testimonial/Testimonial';
import {PlansListPlansInner, PaymentsApi} from '@focusrite-novation/ampify-api';
import {SystemRequirements} from '../components/SystemRequirements/SystemRequirements';
import {FastCollection} from '../components/FastCollection';
import {Spacer} from '../components/Spacing/Spacer';
import {RevealHero} from '../components/RevealHero/RevealHero';
import {KeyFeaturesView} from '../components/KeyFeatures/KeyFeatures.types';
import {getPointsForView} from '../components/KeyFeatures/KeyFeatures.utils';
import {BuyBar} from '../components/BuyBar/BuyBar';
import {createApi} from '../lib/api';
import {Fluid, Point, ProductV2} from '../types/ProductV2';
import {fetchPlans, fetchPurchases} from '../lib/my-account';
import {shouldContinueToPurchase} from '../utils/payment-flow';
import {isChosenPlugin} from '../lib/payment';
import {KeyFeaturesPoint} from '../components/KeyFeatures/KeyFeatures.Point';
import {graphql, useStaticQuery} from 'gatsby';

interface LimiterImages {
  fastView: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
  detailedView: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

const renderRevealKeyFeatures = (
  keyFeaturesView: KeyFeaturesView,
  keyFeaturesPoints: Point[],
  setKeyFeaturesView: (KeyFeaturesView: KeyFeaturesView) => void
) => (
  <>
    <Spacer height="150px" />
    <KeyFeaturesSection>
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <KeyFeaturesLinks>
          <div
            onClick={() => setKeyFeaturesView(KeyFeaturesView.FAST)}
            title="click for FAST view"
            className={
              keyFeaturesView === KeyFeaturesView.FAST ? 'selected' : ''
            }
          >
            FAST
          </div>
          <div
            onClick={() => setKeyFeaturesView(KeyFeaturesView.DETAILED)}
            title="click for DETAILED view"
            className={
              keyFeaturesView === KeyFeaturesView.DETAILED ? 'selected' : ''
            }
          >
            DETAILED
          </div>
        </KeyFeaturesLinks>
        <Spacer height="72px" />
        <KeyFeaturesReveal
          view={keyFeaturesView}
          points={getPointsForView(keyFeaturesPoints, keyFeaturesView)}
        />
      </Gutters>
    </KeyFeaturesSection>
  </>
);

const BalancerPlugin = styled.div`
  position: relative;
`;

const renderBalancerKeyFeatures = (keyFeaturesPoints: Point[]) => {
  const data = useStaticQuery(graphql`
    query BalancerImage {
      balancerUi: file(relativePath: {eq: "balancer-ui.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Spacer height="150px" />
      <KeyFeaturesSection>
        <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
          <BalancerPlugin>
            <Image
              style={{height: '800px', width: 'auto'}}
              imgStyle={{objectFit: 'contain'}}
              fluid={data.balancerUi.childImageSharp.fluid}
            />
            {keyFeaturesPoints.map((point: Point, index: number) => {
              return (
                <KeyFeaturesPoint
                  key={'point-' + index}
                  point={point}
                  index={index}
                />
              );
            })}
          </BalancerPlugin>
          <Spacer height="72px" />
        </Gutters>
      </KeyFeaturesSection>
    </>
  );
};

const renderLimiterKeyFeatures = (
  keyFeaturesView: KeyFeaturesView,
  keyFeaturesPoints: Point[],
  setKeyFeaturesView: (KeyFeaturesView: KeyFeaturesView) => void,
  limiterImages: LimiterImages
) => {
  const image =
    keyFeaturesView === KeyFeaturesView.FAST
      ? limiterImages.fastView.childImageSharp.fixed
      : limiterImages.detailedView.childImageSharp.fixed;
  return (
    <>
      <Spacer height="150px" />
      <KeyFeaturesSection>
        <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
          <KeyFeaturesLinks>
            <div
              onClick={() => setKeyFeaturesView(KeyFeaturesView.FAST)}
              title="click for FAST view"
              className={
                keyFeaturesView === KeyFeaturesView.FAST ? 'selected' : ''
              }
            >
              FAST
            </div>
            <div
              onClick={() => setKeyFeaturesView(KeyFeaturesView.DETAILED)}
              title="click for DETAILED view"
              className={
                keyFeaturesView === KeyFeaturesView.DETAILED ? 'selected' : ''
              }
            >
              DETAILED
            </div>
          </KeyFeaturesLinks>
          <Spacer height="72px" />
          <BalancerPlugin>
            <Image fixed={image} />
            {keyFeaturesPoints
              .filter((point) => point.view === keyFeaturesView)
              .map((point: Point, index: number) => {
                return (
                  <KeyFeaturesPoint
                    key={'point-' + index}
                    point={point}
                    index={index}
                  />
                );
              })}
          </BalancerPlugin>
          <Spacer height="170px" />
        </Gutters>
      </KeyFeaturesSection>
    </>
  );
};

const renderKeyFeatures = (
  location: WindowLocation,
  keyFeaturesView: KeyFeaturesView,
  keyFeaturesPoints: Point[],
  setKeyFeaturesView: (KeyFeaturesView: KeyFeaturesView) => void,
  limiterImages?: LimiterImages
) => {
  if (isChosenPlugin('fast-limiter', location)) {
    return renderLimiterKeyFeatures(
      keyFeaturesView,
      keyFeaturesPoints,
      setKeyFeaturesView,
      limiterImages!
    );
  }
  if (isChosenPlugin('fast-balancer', location)) {
    return renderBalancerKeyFeatures(keyFeaturesPoints);
  }

  return renderRevealKeyFeatures(
    keyFeaturesView,
    keyFeaturesPoints,
    setKeyFeaturesView
  );
};

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  div {
    width: 100%;
  }
  h2 {
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.1em;
    text-align: center;
  }
  p {
    font-family: ${Theme.fonts.body};
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.04em;
    text-align: center;
  }
`;

const KeyFeaturesLinks = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin: auto;
  column-gap: 75px;

  > div {
    font-family: ${Theme.fonts.heading};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-bottom: ${Theme.space[1]}px;
    border-bottom: 3px solid ${Theme.colours.greys[8]};
    color: ${Theme.colours.greys[4]};
    cursor: pointer;
    transition: border-bottom 0.3s, color 0.3s;
  }

  > div:first-child {
    margin-right: 24px;
  }

  > div:hover,
  > div.selected {
    color: ${Theme.colours.white};
    border-bottom: 3px solid white;
  }
`;

const ProductFeatures = styled.section`
  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    margin-top: 50px;
  }
`;

const MessageBar = styled.div`
  width: 100%;
  font-size: ${Theme.fontSizes[1]}px;
  font-weight: bold;
  padding: 0.5rem;
  color: ${Theme.colours.white};
  text-align: center;
  background-color: ${Theme.colours.orange};
  line-height: 1.5;
  margin-top: 60px;

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    margin-top: 70px;
  }
`;

interface ProductPageProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    productContent: ProductV2;
    customerPortalBaseUrl: string;
    ampifyApiBaseUrl: string;
    releasesUrl: string;
    auth: {
      customerPortalBaseUrl: string;
      scope: string;
      client_id: string;
    };
    limiterImages?: LimiterImages;
  };
}

const ProductPage: FunctionComponent<ProductPageProps> = (props) => {
  const product = props.pageContext.productContent;
  const [plans, setPlans] = useState<PlansListPlansInner[]>([]);
  const [keyFeaturesView, setKeyFeaturesView] = useState<KeyFeaturesView>(
    KeyFeaturesView.FAST
  );
  const [usdPrice, setUsdPrice] = useState<undefined | number>();
  const [continueFlow, setContinueFlow] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const setUp = async (api: PaymentsApi) => {
      try {
        const [plansRes, softwareProducts] = await Promise.all([
          api.getPlans(),
          api.getSoftwareProducts(),
        ]);

        const plugin = softwareProducts.data?.products?.find(
          ({name}) => name === product.title
        );
        setUsdPrice(plugin?.price?.USD);
        setPlans(plansRes.data.plans!);

        const [plans, purchases] = await Promise.all([
          fetchPlans(api),
          fetchPurchases(api),
        ]);
        const continueFlow = await shouldContinueToPurchase(
          product.softwareId,
          softwareProducts.data,
          purchases,
          plans
        );
        setContinueFlow(continueFlow);
      } catch (error) {
        setContinueFlow(true);
      }
    };

    const baseUrl = props.pageContext.ampifyApiBaseUrl;

    const api = createApi(PaymentsApi, baseUrl, props.pageContext.auth);

    setUp(api);
  }, []);

  return (
    <Background background={Theme.colours.greys[0]}>
      <Layout
        {...props.pageContext.contentfulFooterContent}
        customerPortalBaseUrl={props.pageContext.customerPortalBaseUrl}
      >
        <SEO
          title={product.title!}
          description={product?.seoDescription?.seoDescription!}
          keywords={product.seoKeywords}
        />
        <Background>
          <RevealHero
            heroTitle={product.heroTitle!}
            prodLogo={product.productLogo!}
            heroDescription={product.heroDescription.heroDescription!}
            heroVideo={product.heroVideo!.file!.url!}
          />

          <BuyBar
            continueFlow={continueFlow}
            plans={plans}
            usdPrice={usdPrice}
            baseUrl={props.pageContext.releasesUrl}
            softwareId={product.softwareId!}
          />

          {isChosenPlugin('fast-balancer', location) && (
            <MessageBar>
              <Gutters maxWidth={GutterMaxWidth.LARGE}>
                <p>
                  100% discount for Focusrite and Novation Registered Hardware
                  Customers applied at checkout. Click Buy Now to redeem.
                </p>
              </Gutters>
            </MessageBar>
          )}

          {Boolean(product.keyFeaturesPoints?.length) &&
            renderKeyFeatures(
              location,
              keyFeaturesView,
              product.keyFeaturesPoints!,
              setKeyFeaturesView,
              props.pageContext.limiterImages
            )}
        </Background>

        <ProductFeatures>
          {product.productFeatures?.map((feature, i) => (
            <ProductFeatureSection
              isOdd={i % 2 !== 0}
              feature={feature!}
              i={i}
              key={feature?.title!}
            />
          ))}
        </ProductFeatures>

        {product.testimonials?.length > 1 && (
          <Background background={Theme.colours.greys[1]}>
            <Spacer height="200px" />
            <Testimonials testimonials={product.testimonials!} />
            <Spacer height="200px" />
          </Background>
        )}

        <Background background={Theme.colours.greys[9]}>
          <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
            <Spacer height="145px" />
            <SystemRequirements product={product} />
          </Gutters>
        </Background>

        <Background background={Theme.colours.greys[0]}>
          <Spacer height="100px" />
          <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
            <FastCollection collectionCards={product.fastCollection} />
          </Gutters>
        </Background>
      </Layout>
    </Background>
  );
};

export default ProductPage;

import styled, {css} from 'styled-components';
import Theme from '../../theme';

interface BuyBarWrapperProps {
  fixed: boolean;
}

export const BuyBarWrapper = styled.div<BuyBarWrapperProps>`
  ${({fixed}) => css`
    display: flex;
    position: ${fixed ? 'fixed' : 'absolute'};
    top: ${fixed ? '70px' : '580px'};
    left: 0px;
    right: 0px;
    z-index: 500;
    background: ${Theme.colours.greys[7]};
    height: 60px;
    button {
      font-family: ${Theme.fonts.heading};
    }

    @media screen and (max-width: ${Theme.breakpoints[1]}px) {
      height: fit-content;
      padding: 15px;
    }

    color: ${Theme.colours.greys[0]};
    text-align: end;

    p {
      margin-right: 25px;
      text-align: center;
      @media screen and (max-width: ${Theme.breakpoints[0]}px) {
        margin-right: 0;
      }
    }

    a {
      font-family: ${Theme.fonts.heading};
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: ${Theme.colours.greys[0]};
      padding: 10px 16px;
      margin: auto 0;
      width: 150px;
      height: 40px;
      background: ${Theme.colours.blue};
      border-radius: 20px;

      @media screen and (max-width: ${Theme.breakpoints[0]}px) {
        margin-top: 15px;
      }
    }
  `}
`;

export const TryContainer = styled.div`
  margin: 0px;

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    margin-bottom: 15px;
  }
`;

export const BuyNowContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  p {
    padding: 0;
    text-align: right;
    line-height: normal;
  }

  p:first-child {
    font-weight: bold;
  }

  p:last-child {
    font-size: ${Theme.fontSizes[1]}px;
  }

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    > div {
      display: none;
    }
  }
`;

interface BuyBarContainerProps {
  isDesktop: boolean;
}

export const BuyBarContainer = styled.div<BuyBarContainerProps>`
  display: flex;
  flex: 1;
  width: 90%;
  justify-content: ${({isDesktop}) =>
    isDesktop ? 'space-between' : 'flex-end'};
  max-width: ${Theme.breakpoints[3]}px;
  margin: auto;

  a {
    margin-top: 0px;
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DownloadWrapper = styled.div`
  #main-download-button {
    min-width: 276px;
  }
  li > div > button {
    border-radius: 20px;
    /* min-width: 276px; */
  }
  li > div > a {
    border-radius: 20px;
    min-width: 276px;
    justify-content: flex-start;
  }
  li:first-child .alternate-download-button {
    justify-content: center;
  }
  .alternate-download-button {
    border-radius: 20px;
    min-width: 276px;
    justify-content: flex-start;
  }

  .alternate-wrapper {
    :hover {
      background-color: transparent;
    }
  }
`;

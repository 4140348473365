interface CustomEvent {
  key: string;
  segmentation?: object;
}

interface FeedbackEvent {
  widget_id: string;
  comment: string;
  rating: number;
  email: string;
  contactMe: boolean;
}

const cookieConsentGiven = () => {
  return (window as any)._iub?.cs?.api?.isConsentGiven() || false;
};

export const sendAnalyticsEvent = (key: string, segmentation?: object) => {
  sendEvent('add_event', {key, segmentation} as CustomEvent);
};

export const sendFeedbackEvent = (data: object) => {
  sendEvent('report_feedback', {
    widget_id: '1234567890',
    rating: 3,
    email: '',
    contactMe: false,
    ...data,
  } as FeedbackEvent);
};

const sendEvent = (event: string, data: CustomEvent | FeedbackEvent) => {
  if (cookieConsentGiven()) {
    const interval = setInterval(() => {
      if (window.hasOwnProperty('Countly')) {
        (window as any).Countly.q.push([event, data]);
        clearInterval(interval);
      }
    }, 200);
  }
};

export const getConfigValue = (key: string) => {
  if (
    window.hasOwnProperty('Countly') &&
    typeof (window as any)?.Countly?.get_remote_config === 'function' &&
    cookieConsentGiven()
  ) {
    const value = (window as any)?.Countly?.get_remote_config(key);
    return value;
  }
};
